import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// components
import LoadingScreen from '../components/loading-screen';
//
import Login from '../pages/LoginPage';
import { useAuthContext } from './useAuthContext';
export default function AuthGuard(_a) {
    var children = _a.children;
    var _b = useAuthContext(), isAuthenticated = _b.isAuthenticated, isInitialized = _b.isInitialized, user = _b.user, logout = _b.logout;
    var pathname = useLocation().pathname;
    var _c = useState(null), requestedLocation = _c[0], setRequestedLocation = _c[1];
    if (!isInitialized) {
        return _jsx(LoadingScreen, {});
    }
    if (!isAuthenticated) {
        if (pathname !== requestedLocation) {
            setRequestedLocation(pathname);
        }
        return _jsx(Login, {});
    }
    if (!(user === null || user === void 0 ? void 0 : user.hasAccess)) {
        logout();
        return _jsx(Navigate, { to: "/403" });
    }
    if (requestedLocation && pathname !== requestedLocation) {
        setRequestedLocation(null);
        return _jsx(Navigate, { to: requestedLocation });
    }
    return _jsxs(_Fragment, { children: [" ", children, " "] });
}
